<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="add_customer" aria-labelledby="sidebar-no-header-title" width="80rem" no-header backdrop style="direction:ltr" right title="اضافة عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div v-if="$store.state.group_id != 9" style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{formTitle}}</span>
                </div>
                <div v-else>
                    &nbsp;
                </div>
                <div @click="hide" id="hidaddcus" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
        </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <div class="tobbuttom" v-if="$store.state.group_id != 9 && $store.state.licenseType.showCustomerTypes" :style="`direction:${lang.dir};padding:7px;width:100%;text-align:center;background:#efefef;border:1px solid #bbb;`">
                <b-button :style="`border-radius:0;background:transparent;color:#000;border:none;`">{{lang.chose_customer_type}}</b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:.8rem !important;font-weight: bolder;width:100px" id="btn0" :class="`dispFlex weightColor borderRad_`+lang.algin" @click="changeCusType(0)" >{{lang.individual_client}} <i id="icon0"  class="fas fa-arrow-down"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:.8rem !important;font-weight: bolder;width:100px" id="btn1" :class="`dispFlex blackBack borderRad_none`" @click="changeCusType(1)" >{{lang.company_clinet}} <i id="icon1" class="fas fa-arrow-down hideIcon"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:.8rem !important;font-weight: bolder;width:100px" id="btn2" :class="`dispFlex weightColor borderRad_`+lang.lalgin" @click="changeCusType(2)" >{{lang.government_clinet}} <i id="icon2" class="fas fa-arrow-down hideIcon"></i></b-button>
            </div>
            <v-row>
                <v-col md="12">
                    <div class="backBalck text-center p-2 m-1 mt-3" style="border:1px solid #efefef;background:#bbb">{{ lang.customer_info }}</div>
                </v-col>
                <v-col cols="12" md="3" sm="12" v-if="showAccountType">
                    <label>{{ lang.chose_from_list }} {{lang.account_client_type}}</label>
                    <div>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            :label="lang.account_client_type"
                            :options="clientTypes"
                            v-model="customers.client_type"
                            />
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </v-col>
                <v-col cols="12" md="3" sm="12" v-if="showAccountType">
                    <label>{{ lang.chose_from_list }} {{lang.main_clients}}</label>
                    <div>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            :label="lang.main_clients"
                            :options="mainClients"
                            v-model="customers.main_clients"
                            />
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{ lang.chose_from_list }} {{lang.client_source}}</label>
                    <div>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            :label="lang.client_source"
                            :options="clientSource"
                            v-model="customers.client_source"
                            />
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="$store.state.licenseType.showCustomerId">
                    <label>{{lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label='lang.customerid'
                        v-model="customers.customerid"
                        :rules="mobileRules"
                        >{{ customers.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="!showCom && !showGov">
                    <label>{{lang.id_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label='lang.id_number'
                        v-model="customers.id_number"
                        
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label='lang.mobile'
                        v-model="customers.mobile"
                        @change="checkCustomer()"
                        
                        :rules="mobileRules"
                        >{{ customers.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="$store.state.licenseType.showAddNumber">
                    <label>{{lang.additional_mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.additional_mobile"
                        v-model="customers.mobile1"
                        @change="checkCustomer()"
                        
                        >{{ customers.mobile1 }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{lang.customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.customer_name"
                        v-model="customers.full_name"
                        :rules="nameRules"
                        >{{ customers.full_name }}</b-form-input>
                </v-col>

                <v-col cols="12" md="3" sm="12">
                    <label>{{lang.client_email}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.email"
                        v-model="customers.email"
                        :rules="nameRules"
                        ></b-form-input>
                </v-col>

                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{lang.company_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.company_name"
                        v-model="customers.company_name"
                        >{{ customers.company_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" v-if="showCom">
                    <label>{{lang.company_vatid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.company_vatid"
                        v-model="customers.company_vatid"
                        
                        >{{ customers.company_vatid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.crt_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.crt_number"
                        v-model="customers.crt_number"
                        
                        >{{ customers.crt_number }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.city}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.city"
                        v-model="customers.city"
                        
                        >{{ customers.city }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" v-if="$store.state.licenseType.isBill">
                    <label> {{ lang.chose_from_list }} {{lang.industrial}}</label>
                    <div>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            :label="lang.industrial"
                            :options="inDustrials"
                            v-model="customers.industrial"
                            />
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </v-col>
                
                <v-col cols="12" md="3" sm="12" v-if="showCom">
                    <label>{{lang.street_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.street_name"
                        v-model="customers.street_name"
                        
                        >{{ customers.street_name }}</b-form-input>
                </v-col>

                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.building_no}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.building_no"
                        v-model="customers.building_no"
                        
                        >{{ customers.building_no }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.branch_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.branch_number"
                        v-model="customers.branch_number"
                        
                        >{{ customers.branch_number }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.district}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.district"
                        v-model="customers.district"
                        
                        >{{ customers.district }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.zipcode}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.zipcode"
                        v-model="customers.zipcode"
                        
                        >{{ customers.zipcode }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{lang.address}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.address"
                        v-model="customers.address"
                        
                        >{{ customers.address }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showGov">
                    <label>{{lang.entity_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.entity_name"
                        v-model="customers.entity_name"
                        
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showGov">
                    <label>{{lang.branch_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.branch_name"
                        v-model="customers.branch_name"
                        
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label> {{ lang.chose_from_list }} {{lang.client_activity}}</label>
                    <b-input-group>
                        <b-form-select
                        class="selborder"
                        v-model="customers.client_activity"
                        :options="static.clients_activities"
                        />
                        <b-input-group-append style="margin-right: -20px; margin-top: 5px">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label> {{ lang.chose_from_list }} {{lang.client_sub_activity}}</label>
                    <b-input-group>
                        <b-form-select
                        class="selborder"
                        v-model="customers.client_sub_activity"
                        :options="static.clients_sub_activities"
                        />
                        <b-input-group-append style="margin-right: -20px; margin-top: 5px">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.city}}</label>
                    <b-input-group>
                        <b-form-select
                        class="selborder"
                        v-model="customers.type_of_payments"
                        :options="static.type_of_payments"
                        />
                        <b-input-group-append style="margin-right: -20px; margin-top: 5px">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                    <v-row>
                        <v-col cols="12" md="3" sm="12">
                            <label>{{lang.response_name}}</label>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>{{lang.response_email}}</label>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>{{lang.response_mobile}}</label>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>{{lang.action}}</label>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in customers.response_person" :key="index">
                        <v-col cols="12" md="3" sm="12">
                            <label>{{item.response_name}}</label>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>{{item.response_email}}</label>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>{{item.response_mobile}}</label>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <b-button type="button" class="btn-sm" variant="success" style="width:80px;font-weight:bolder;background:red;border:none;" @click="delRP(index)">{{ lang.delete}}</b-button>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="3" sm="12">
                            <b-form-input
                                class="inborder"
                                v-model="newrow.response_name"
                                ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <b-form-input
                                class="inborder"
                                v-model="newrow.response_email"
                                ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <b-form-input
                                class="inborder"
                                v-model="newrow.response_mobile"
                                ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <b-button type="button" class="btn-sm" variant="success" style="width:80px;font-weight:bolder;background:green;border:none;" @click="addNewRow()">{{ lang.add}}</b-button>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="12" v-if="$store.state.licenseType.isCRM">
                    <div class="backBalck text-center p-2 m-1 mt-3" style="border:1px solid #efefef;background:#bbb">{{ lang.deal_info }}</div>
                </v-col>
                <v-col md="12" v-if="$store.state.licenseType.isCRM">
                    <v-row>
                        <v-col cols="12" md="3" sm="12" v-for="(item, index) in static.services_list" :key="index">
                            <label> {{ lang.chose_from_list }} {{lang.service}}</label>
                                <b-form-checkbox
                                class="inborder"
                                @change="changeService(item.value)"
                                >{{ item.text }}</b-form-checkbox>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="12" sm="12" v-if="$store.state.licenseType.isCRM">
                    <v-row>
                        <v-col cols="12" md="12" sm="12" v-if="showTransport">
                            <v-row v-for="(item,index) in deal.transport" :key="index">
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.transport_destinations}}</label>
                                    <b-input-group>
                                        <b-form-select
                                        class="selborder"
                                        v-model="deal.transport[index].transport_destinations"
                                        :options="static.transport_destinations"
                                        />
                                        <b-input-group-append style="margin-right: -20px; margin-top: 5px">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </v-col> 
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.container_number}}</label>
                                    <b-form-input
                                        class="inborder"
                                        v-model="deal.transport[index].container_number"
                                        ></b-form-input>
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                    <label> {{ lang.chose_from_list }} {{lang.container_type}}</label>
                                    <b-input-group>
                                        <b-form-select
                                        class="selborder"
                                        v-model="deal.transport[index].container_type"
                                        :options="static.container_types"
                                        />
                                        <b-input-group-append style="margin-right: -20px; margin-top: 5px">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <label>{{lang.container_weight}}</label>
                                        <b-form-input
                                        class="inborder"
                                        v-model="deal.transport[index].container_weight"
                                        />
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.port_of_arrival}}</label>
                                        <b-form-input
                                        class="inborder"
                                        v-model="deal.transport[index].port_of_arrival"
                                        />
                                </v-col>
                                <v-col cols="12" md="1" sm="12">
                                    <label>{{ lang.action }}</label>
                                    <b-button type="button" class="btn-sm" variant="success" style="width:80px;font-weight:bolder;background:red;border:none;" @click="deleteTrans(index)">{{ lang.delete}}</b-button>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.transport_destinations}}</label>
                                    <b-input-group>
                                        <b-form-select
                                        class="selborder"
                                        v-model="tNewRow.transport_destinations"
                                        :options="static.transport_destinations"
                                        />
                                        <b-input-group-append style="margin-right: -20px; margin-top: 5px">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </v-col> 
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.container_number}}</label>
                                    <b-form-input
                                        class="inborder"
                                        v-model="tNewRow.container_number"
                                        ></b-form-input>
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                    <label> {{ lang.chose_from_list }} {{lang.container_type}}</label>
                                    <b-input-group>
                                        <b-form-select
                                        class="selborder"
                                        v-model="tNewRow.container_type"
                                        :options="static.container_types"
                                        />
                                        <b-input-group-append style="margin-right: -20px; margin-top: 5px">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <label>{{lang.container_weight}}</label>
                                        <b-form-input
                                        class="inborder"
                                        v-model="tNewRow.container_weight"
                                        />
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.port_of_arrival}}</label>
                                        <b-form-input
                                        class="inborder"
                                        v-model="tNewRow.port_of_arrival"
                                        />
                                </v-col>
                                <v-col cols="12" md="1" sm="12">
                                    <label>{{ lang.action }}</label>
                                    <b-button type="button" class="btn-sm" variant="success" style="width:80px;font-weight:bolder;background:green;border:none;" @click="addnewTrans()">{{ lang.add}}</b-button>
                                </v-col>
                            </v-row>
                        </v-col>
                           
                        <v-col cols="12" md="3" sm="12" v-if="showInterCargo">
                            <label>{{lang.type_of_cargo}}</label>
                            <b-input-group>
                                <b-form-select
                                class="selborder"
                                v-model="deal.type_of_cargo"
                                :options="static.type_of_cargo"
                                />
                                <b-input-group-append style="margin-right: -20px; margin-top: 5px">
                                <i class="fas fa-arrow-down"></i>
                                </b-input-group-append>
                            </b-input-group>
                        </v-col>    
                        <v-col cols="12" md="3" sm="12" v-if="showInterCargo && (deal.type_of_cargo == 1 || deal.type_of_cargo == 2) ">
                            <label>{{lang.port_of_cargo}}</label>
                            <b-form-input
                                class="inborder"
                                v-model="deal.port_of_cargo"
                                />
                        </v-col>  
                        <v-col cols="12" md="3" sm="12" v-if="showInterCargo && deal.type_of_cargo == 3 ">
                            <label>{{lang.supplier_address}}</label>
                            <b-form-input
                                class="inborder"
                                v-model="deal.supplier_address"
                                />
                        </v-col>
                        <v-col cols="12" md="3" sm="12" v-if="showInterCargo">
                            <label>{{lang.port_of_arrival}}</label>
                            <b-form-input
                                class="inborder"
                                v-model="deal.port_of_arrival"
                                />
                        </v-col>
                        <v-col cols="12" md="12" sm="12" v-if="showCustom">
                            <v-row>
                                <v-col cols="12" md="3" sm="12">
                                    <label>{{lang.port_of_arrival}}</label>
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <label>{{lang.number_of_container_monthly}}</label>
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <label>{{lang.number_of_container_by_policy}}</label>
                                </v-col>
                                <v-col cols="12" md="3" sm="12"></v-col>
                            </v-row>
                            <v-row v-for="(item,index) in deal.customs" :key="index">
                                <v-col cols="12" md="3" sm="12">
                                    <b-form-input
                                        class="inborder"
                                        v-model="deal.customs[index].port_of_arrival"
                                        />
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <b-form-input
                                        class="inborder"
                                        v-model="deal.customs[index].number_of_container_monthly"
                                        />
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <b-form-input
                                        class="inborder"
                                        v-model="deal.customs[index].number_of_container_by_policy"
                                        />
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <b-button type="button" class="btn-sm" variant="success" style="width:80px;font-weight:bolder;background:red;border:none;" @click="delCRow(index)">{{ lang.delete}}</b-button>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="3" sm="12">
                                    <b-form-input
                                        class="inborder"
                                        v-model="cNewRow.port_of_arrival"
                                        />
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <b-form-input
                                        class="inborder"
                                        v-model="cNewRow.number_of_container_monthly"
                                        />
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <b-form-input
                                        class="inborder"
                                        v-model="cNewRow.number_of_container_by_policy"
                                        />
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <b-button type="button" class="btn-sm" variant="success" style="width:80px;font-weight:bolder;background:green;border:none;" @click="addCNewRow()">{{ lang.add}}</b-button>
                                </v-col>
                            </v-row>
                        </v-col>
                        
                    </v-row>
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
            <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addCustomer();" variant="success" class="ma-2" style="width:100px;">{{lang.save}}</b-button>
            </div>
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default{
    data() {
        return {
            showAccountType: false,
            deal: {
                service_require: [],
                transport: [],
                port_of_arrival: '',
                port_of_destination: '',
                country_of_origin: '',
                nature_of_nuloading: 1,
                type_of_payments: 1,
                type_of_cargo: 1,
                port_of_cargo: '',
                supplier_address: '',
                customs: []
            },
            tNewRow: {
                container_number: '',
                container_type: 1,
                container_weight: '',
                port_of_arrival: '',
                transport_destinations: 1,
            },
            cNewRow: {
                port_of_arrival: '',
                number_of_container_monthly: '',
                number_of_container_by_policy: ''
            },
            newrow: {
                response_name: '',
                response_mobile: '',
                response_email: '',
            },
            mainAccounts: [],
            customers:{
                type_of_payments: 1,
                response_person: [],
                client_activity: 1,
                client_sub_activity: 1,
                main_clients: 0,
                client_type: 1,
                id: 0,
                mobile: '',
                mobile1: '',
                email: '',
                full_name: '',
                company_name: '',
                company_vatid: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                customer_type: 1,
                entity_name: '',
                branch_name: '',
                id_number: '',
                industrial: 0,
                client_source: 1,
                crt_number: '',
                nationality: "",
            },
            showCom: true,
            showGov: false,
            optionsS: [],
            notes: '',
            editMode: false,
            static_options: [],
            sources: [],
            showCustom: false,
            showTransport: false,
            showInterCargo: false,
        }
    },
    computed: {
        carNums: function(){
            let t= [];
            for(let i=0;i<this.car_number;i++){
                t.push(i)
            }
            console.log(t);
            return t;
        },
        carsNumbers: function(){
            return [
                {text: 1, value: 1},
                {text: 2, value: 2},
                {text: 3, value: 3},
                {text: 4, value: 4},
                {text: 5, value: 5},
                {text: 6, value: 6}
            ]
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        static: function()
        {
            let t = {}
            
            Object.keys(this.static_options).forEach(key => {
                for(let i=0; i< this.static_options[key].length;i++){
                    t[`${key}`] = [];
                }
                for(let i=0; i< this.static_options[key].length;i++){
                    t[`${key}`].push({text: this.static_options[key][i][`name_${this.lang.langname}`], value: this.static_options[key][i].value})
                }
            })
            return t;
        },
        mainClients: function(){
            let t = [{text: this.lang.without_account, value: 0}];
            for(let i=0;i<this.mainAccounts.length;i++){
                t.push({text: this.mainAccounts[i].customer_name, value: this.mainAccounts[i].id})
            }
            return t;
        },
        clientTypes: function(){
            return [
                {text: this.lang.customer_client, value: 1},
                {text: this.lang.customer_group_client, value: 2}
            ]
        },
        techNames: function(){
            let t = [{text: 'اختر' , value: ''}];
            for(let i=0;i<this.techs.length;i++){
                t.push({text: this.techs[i].employee_name,value: this.techs[i].id})
            }
            return t;
        },
        workTypes: function(){
            let t = [{text: 'اختر' , value: ''}];
            for(let i=0;i<this.works.length;i++){
                t.push({text: this.works[i].name_ar,value: this.works[i].value})
            }
            return t;
        },
        programs: function(){
            let t = [{text: 'اختر' , value: ''}];
            for(let i=0;i<this.programsList.length;i++){
                t.push({text: this.programsList[i].campaign_title,value: this.programsList[i].id})
            }
            return t;
        },
        formTitle: function (){
            if(this.editMode) {
                return this.lang.update_customer;
            }
            return this.lang.add_customer;
        },
        mobileRules: function() {
            return [
                v => !!v || this.lang.required_field,
                v => (v && v.length <= 10) || this.lang.mobil_10_digits,
            ]
        },
        nameRules: function() {
            return [
                v => !!v || this.lang.required_field,
            ]
        },

        clientSource: function(){
            let t = [];
            for (let i = 0; i < this.sources.length; i++) {
                t.push({
                    text: this.sources[i].name_ar,
                    value: this.sources[i].value,
                });
            }
            return t;
        },

    }, 
    created(){
        this.getStaticid('customerSource');
        this.getStaticid(1);
        this.getSettings();
        this.getAllMain();
    },
    methods: {
        deleteTrans(index){
            this.deal.transport.splice(index,1);
        },
        addnewTrans(){
            this.deal.transport.push(this.tNewRow);
            this.tNewRow = {
                container_number: '',
                container_type: 1,
                container_weight: '',
                port_of_arrival: '',
                transport_destinations: 1,
            }
        },
        delCRow(index){
            this.deal.customs.splice(index,1);
        },
        addCNewRow(){
            this.deal.customs.push(this.cNewRow);
            this.cNewRow = {
                port_of_arrival: '',
                number_of_container_monthly: '',
                number_of_container_by_policy: ''
            }

        },
        changeService(value) {
            // Ensure value is a number
            value = Number(value);

            // Filter out observer objects
            this.deal.service_require = this.deal.service_require.filter(item => typeof item === 'number');

            let index = this.deal.service_require.indexOf(value);

            if (index !== -1) {
                this.deal.service_require.splice(index, 1);
                console.log(`Removed value ${value} at index ${index}`);
            } else {
                this.deal.service_require.push(value);
                console.log(`Added value ${value}`);
            }

            console.log('Array after change:', this.deal.service_require);

            this.showCustom = this.deal.service_require.includes(2);
            this.showTransport = this.deal.service_require.includes(3);
            this.showInterCargo = this.deal.service_require.includes(1);

            console.log('Flags:', {
                showCustom: this.showCustom,
                showTransport: this.showTransport,
                showInterCargo: this.showInterCargo
            });
        },
        addNewRow() {
            this.customers.response_person.push(this.newrow);
            this.newrow = {
                response_name: '',
                response_mobile: '',
                response_email: '',
            }
        },
        delRP(index) {
            this.customers.response_person.splice(index);
        },
        clearAll(){
            this.tNewRow = {
                container_number: '',
                container_type: 1,
                container_weight: '',
                port_of_arrival: '',
                transport_destinations: 1,
            }
            this.showAccountType =  false
            this.deal =  {
                service_require: [],
                transport: [],
                port_of_arrival: '',
                port_of_destination: '',
                country_of_origin: '',
                nature_of_nuloading: 1,
                type_of_payments: 1,
                type_of_cargo: 1,
                port_of_cargo: '',
                supplier_address: '',
                customs: []
            }
            this.newrow =  {
                response_name: '',
                response_mobile: '',
                response_email: '',
            }
            this.cNewRow = {
                port_of_arrival: '',
                number_of_container_monthly: '',
                number_of_container_by_policy: ''
            }
            this.mainAccounts = []
            this.customers = {
                type_of_payments: 1,
                response_person: [],
                client_activity: 1,
                client_sub_activity: 1,
                main_clients: 0,
                email: '',
                client_type: 1,
                id: 0,
                mobile: '',
                mobile1: '',
                full_name: '',
                company_name: '',
                company_vatid: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                customer_type: 1,
                entity_name: '',
                branch_name: '',
                id_number: '',
                industrial: 0,
                client_source: 1,
                crt_number: '',
                nationality: ""
            }
            this.showCom = true
            this.showGov = false
            this.optionsS = []
            this.notes = ''
            this.editMode = false
            this.static_options = []
            this.getStaticid(1);
            this.getSettings();
            this.showCustom = false
            this.showTransport = false
            this.showInterCargo = false
        },
        growCar(){
            if(this.car.length > this.car_number){
                let cars = [];
                for(let i=0;i<this.car_number;i++){
                    cars.push(this.car[i]);
                }
                this.car = cars;
            }
            else if(this.car.length < this.car_number){
                let  n = parseInt(this.car_number) - parseInt(this.car.length);
                for(let j=1;j<=n;j++){
                    
                    this.car.push({
                        plate: {
                            f1: '',
                            f2: '',
                            f3: '',
                            text: ''
                        },
                        plate_number: '',
                        brand: '',
                        model: '',
                        vin: '',
                        color: '',
                        car_year: '',

                    });
                }
            }
        },
        getAllMain(){
            const post = new FormData();
            post.append("type", "getMainCustomers");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", 0);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.mainAccounts = response.data.results.data
            });
        },
        getSettings(){
            const post = new FormData();
            post.append("type", "getAddSettings");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", 0);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.programsList = response.data.results.progs
                this.techs = response.data.results.techs
                this.works = response.data.results.works
            });
        },
        updateCustomer(item){
            this.customers = {
                main_clients: item.main_clients,
                client_type: item.client_type,
                id: item.id,
                mobile: item.mobile1,
                mobile1: item.mobile2 == "-" ? '' : item.mobile2,
                full_name: item.full_name,
                company_name: item.company_name,
                company_vatid: item.company_vatid,
                building_no: item.building_no,
                street_name: item.street_name,
                branch_number: item.branch_number,
                district: item.district,
                zipcode: item.zipcode,
                city:item.city,
                address:item.address,
                customer_type: item.customer_type,
                entity_name: item.entity_name,
                branch_name: item.branch_name,
                id_number: item.id_number,
                industrial: item.industrial,
                client_source: item.client_source ? item.client_source : 0 ,
                crt_number: item.crt_number,
                nationality: item.nationality,
            }
            this.changeCusType(parseFloat(item.customer_type) - 1)
        },
        getStaticid(id) {
            const post = new FormData();
            post.append("type", "getStaticOptions");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", id);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                // // console.log("i am here",response.data)
                if(id == 1)
                    this.static_options = response.data.results.res1;
                if(id == 'convertProducts')
                    this.optionsS = response.data.results.data;
                else if(id == 'customerSource')
                    this.sources = response.data.results.data
                else if(id == 'inDustrial')
                    this.inDusts = response.data.results.data
                // // console.log("i am here",this.optionsS);
            });
        },
        checkCustomer(){
            const post = new FormData();
            post.append("type", "customerInfoAllM");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[id]", this.customers.id);
            post.append("data[mobile]", this.customers.mobile);
            post.append("data[mobile1]", this.customers.mobile1);

            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data && response.data.results.data[0]){
                    console.log("customer",  response.data.results.data[0]);
                    this.customers = {
                        type_of_payments: response.data.results.data[0].type_of_payments,
                        response_person: response.data.results.data[0].response_person,
                        client_activity: response.data.results.data[0].client_activity,
                        client_sub_activity: response.data.results.data[0].client_sub_activity,
                        main_clients: response.data.results.data[0].main_clients ,
                        client_type: response.data.results.data[0].client_type ,
                        email: response.data.results.data[0].email ,
                        id: response.data.results.data[0].id ,
                        mobile: this.customers.mobile,
                        mobile1: this.customers.mobile1,
                        full_name: response.data.results.data[0].full_name ,
                        company_name: response.data.results.data[0].company_name ,
                        company_vatid: response.data.results.data[0].company_vatid ,
                        building_no: response.data.results.data[0].building_no ,
                        street_name: response.data.results.data[0].street_name,
                        branch_number: response.data.results.data[0].branch_number ,
                        district: response.data.results.data[0].district ,
                        zipcode: response.data.results.data[0].zipcode ,
                        city:response.data.results.data[0].city ,
                        address:response.data.results.data[0].address ,
                        customer_type: response.data.results.data[0].customer_type ,
                        entity_name: response.data.results.data[0].entity_name ,
                        branch_name: response.data.results.data[0].branch_name ,
                        id_number: response.data.results.data[0].id_number ,
                        industrial: response.data.results.data[0].industrial ,
                        client_source: response.data.results.data[0].client_source ,
                        crt_number: response.data.results.data[0].crt_number ,
                        nationality: response.data.results.data[0].nationality ,
                    }
                    this.changeCusType(parseFloat(response.data.results.data[0].customer_type) - parseFloat(1))
                }
            });
        },
        changeCusType(id){
            for(let i=0;i<3;i++){
                document.getElementById(`btn${i}`).classList.remove('blackBack');
                document.getElementById(`btn${i}`).classList.add('weightColor');
                if(i == id){
                    document.getElementById(`btn${i}`).classList.add('blackBack');
                    document.getElementById(`btn${i}`).classList.remove('weightColor');
                    document.getElementById(`icon${id}`).classList.remove('hideIcon')
                }else{
                    document.getElementById(`icon${i}`).classList.add('hideIcon')
                }
            }
            if(id == 0){
                this.customers.customer_type = 1;
                this.showCom = false;
                this.showGov = false;
                
            }else if(id == 1){
                this.customers.customer_type = 2;
                this.showCom = true;
                this.showGov = false;
            }
            else if(id == 2){
                this.customers.customer_type = 3;
                // this.showcamp = true;
                this.showCom = false;
                this.showGov = true;
            }
            // this.changeCtype();
        },
        addCustomer(){
            let vald = true;
            let message = "";
            if(this.$store.state.licenseType.fullMobil){
                if(this.customers.mobile == '' || this.customers.mobile.length < 5 || this.customers.mobile.substring(0,2) != "05"){
                    vald = false;
                    message = this.lang.mobile_info_required_fullmobile;
                }
            }
            else if(this.customers.mobile == '' || this.customers.mobile.length < 4){
                vald = false;
                message = this.lang.mobile_info_required;
            }
            
            else if(this.customers.full_name == ''  && vald == true){
                vald = false;
                message = this.lang.name_info_required;
            }
            else if((this.customers.customer_type == 2 || this.customers.customer_type == 3) && vald == true){
                if(this.customers.company_name == '' && this.customers.customer_type == 2){
                    vald = true;
                    // message = this.lang.customer_company_info_required;
                    this.customers.company_name = this.customers.full_name;
                }
                if(this.customers.entity_name == '' && this.customers.customer_type == 3){
                    vald = true;
                    // message = this.lang.customer_company_info_required;
                    this.customers.entity_name = this.customers.full_name;
                }
            }
            else if(this.customers.industrial == '' && this.$store.state.licenseType.industrialRequired){
                vald = false;
                message = "الصناعية دائما مطلوبة وفي حال كان العميل خارج الرياض يرجى كتابة اسم المدينة";
            }

            if(vald == false){
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type" , "addCustomerCRM");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[notes]",this.notes)
            post.append("data[expected_amount]",this.expected_amount)
            post.append("data[deal]",JSON.stringify(this.deal))
            post.append("data[customer]",JSON.stringify(this.customers))
            
            axios.post(
                this.$SAMCOTEC.r_path , post
            ).then((response) => {
                var res = response.data;
                if(res.error.number == 200){
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    message = this.lang.customer_addedd;
                    this.$snotify.error(message, this.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    
                    this.customers = {
                        main_clients: 0,
                        client_type: 1,
                        id: 0,
                        mobile: '',
                        mobile1: '',
                        full_name: '',
                        company_name: '',
                        company_vatid: '',
                        building_no: '',
                        street_name: '',
                        branch_number: '',
                        district: '',
                        zipcode: '',
                        city:'',
                        address:'',
                        customer_type: 1,
                        entity_name: '',
                        branch_name: '',
                        id_number: '',
                        industrial: 0,
                        client_source: 1,
                        crt_number: '',
                        nationality: "",
                    }
                    document.getElementById('hidaddcus').click();
                    this.$parent.getClients();
                }else{
                    message = res.error.message;
                    this.$snotify.error(message, this.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    
                    if(res.error.number == 202){
                        this.$parent.customer.mobile = this.customers.mobile;
                        this.$parent.customer.full_name = this.customers.full_name;
                        
                        document.getElementById("add_customer").style.display = "none"
                    }
                }
            })
        }
    },
}
</script>
<style scoped>
.hideIcon {
    display:none;
}
.borderRad_right,  .borderRad_left, .borderRad_none{
    padding:2px 5px !important;
}
</style>